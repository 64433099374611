<template>
  <div id="appointmentDetails" class="px-4 lg:px-12">
    <div class="row">
      <div class="col-12 title">{{ appointmentInfo.title }}</div>
      <div class="tabs">
        <div
          :class="`tab cursor-pointer ${!isPane1active ? 'active' : ''}`"
          @click="isPane1active = false"
        >
          Profile details
        </div>
        <div
          :class="`tab cursor-pointer ${isPane1active ? 'active' : ''}`"
          @click="isPane1active = true"
        >
          Profile details
        </div>
      </div>
      <div v-show="isPane1active" class="appointment-details">
        <div
          class="mx-auto sm:w-full lg:w-9/12 lg:flex items-center justify-center details"
        >
          <div class="d-flex">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7638 17.364L12.9146 23.728L6.06547 17.364C4.71086 16.1054 3.78836 14.5017 3.41463 12.7559C3.0409 11.0101 3.23272 9.20047 3.96584 7.55595C4.69896 5.91142 5.94045 4.50582 7.53332 3.51689C9.12619 2.52796 10.9989 2.00012 12.9146 2.00012C14.8303 2.00012 16.703 2.52796 18.2959 3.51689C19.8888 4.50582 21.1303 5.91142 21.8634 7.55595C22.5965 9.20047 22.7883 11.0101 22.4146 12.7559C22.0409 14.5017 21.1184 16.1054 19.7638 17.364ZM12.9146 15C14.0564 15 15.1513 14.5786 15.9587 13.8285C16.766 13.0783 17.2196 12.0609 17.2196 11C17.2196 9.93918 16.766 8.92176 15.9587 8.17162C15.1513 7.42147 14.0564 7.00004 12.9146 7.00004C11.7729 7.00004 10.6779 7.42147 9.87057 8.17162C9.06324 8.92176 8.60969 9.93918 8.60969 11C8.60969 12.0609 9.06324 13.0783 9.87057 13.8285C10.6779 14.5786 11.7729 15 12.9146 15ZM12.9146 13C12.3437 13 11.7963 12.7893 11.3926 12.4143C10.9889 12.0392 10.7622 11.5305 10.7622 11C10.7622 10.4696 10.9889 9.9609 11.3926 9.58583C11.7963 9.21076 12.3437 9.00004 12.9146 9.00004C13.4855 9.00004 14.033 9.21076 14.4366 9.58583C14.8403 9.9609 15.0671 10.4696 15.0671 11C15.0671 11.5305 14.8403 12.0392 14.4366 12.4143C14.033 12.7893 13.4855 13 12.9146 13Z"
              />
            </svg>

            {{ appointmentInfo.location }}
          </div>
          <div class="d-flex">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 9V5H17V7H15V5H9V7H7V5H4V9H20ZM20 11H4V19H20V11ZM6 13H11V17H6V13Z"
              />
            </svg>

             {{ appointmentInfo.date_time |formatDate("date") }} 
          </div>
          <div class="d-flex">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 12V7H11V14H17V12H13Z"
              />
            </svg>
            {{ appointmentInfo.date_time | formatDate("time") }} 
          
          </div>
        </div>
        <div class="w-full text-left sectiony">
          <div class="heading">Profile description</div>
          <div class="description">
            {{ appointmentInfo.description }}
          </div>
        </div>
        <!-- <div class="w-full text-left sectiony last">
          <div class="heading">Reminder</div>
          <div class="description">{{ appointmentInfo.reminder }}</div>
        </div> -->
      </div>
      <div v-show="!isPane1active" class="user-details">
        <div class="md:flex margin_bottom">
          <div class="image">
            <vue-fullscreen v-if="userInfo.hasProfile" ref="fullscreen" v-model="fullscreen">
              <img
                  v-if="userInfo.hasProfile"
                  @click="toggle"
                  :src="`http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/profile/${userInfo.id}`"
                  alt=""
                  :class="{'full':fullscreen}"
                  class="mx-auto"
              />
            </vue-fullscreen>
<!--            <img-->
<!--                @click="toggle"-->
<!--              v-if="userInfo.hasProfile"-->
<!--              :src="`http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/profile/${userInfo.id}`"-->
<!--              alt=""-->
<!--              class="mx-auto"-->
<!--            />-->
            <Avatar
              v-else
              :fullname="userInfo.names"
              color="#054D6F"
              :size="130"
              class="mt-1 sm:mt-2 avatar cursor-pointer"
              id="prof-avatar"
            ></Avatar>
            <div class="text">
              {{ userInfo.names ? userInfo.names : "Appointee not yet attached" }}
            </div>
          </div>
          <div class="details md:mx-16 mt-4 md:mt-0">
            <div>
              <div
                class="mx-auto sm:w-full lg:w-full lg:flex items-center details gap-x-36"
              >
                <div class="d-flex">
                  <svg
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7638 17.364L12.9146 23.728L6.06547 17.364C4.71086 16.1054 3.78836 14.5017 3.41463 12.7559C3.0409 11.0101 3.23272 9.20047 3.96584 7.55595C4.69896 5.91142 5.94045 4.50582 7.53332 3.51689C9.12619 2.52796 10.9989 2.00012 12.9146 2.00012C14.8303 2.00012 16.703 2.52796 18.2959 3.51689C19.8888 4.50582 21.1303 5.91142 21.8634 7.55595C22.5965 9.20047 22.7883 11.0101 22.4146 12.7559C22.0409 14.5017 21.1184 16.1054 19.7638 17.364ZM12.9146 15C14.0564 15 15.1513 14.5786 15.9587 13.8285C16.766 13.0783 17.2196 12.0609 17.2196 11C17.2196 9.93918 16.766 8.92176 15.9587 8.17162C15.1513 7.42147 14.0564 7.00004 12.9146 7.00004C11.7729 7.00004 10.6779 7.42147 9.87057 8.17162C9.06324 8.92176 8.60969 9.93918 8.60969 11C8.60969 12.0609 9.06324 13.0783 9.87057 13.8285C10.6779 14.5786 11.7729 15 12.9146 15ZM12.9146 13C12.3437 13 11.7963 12.7893 11.3926 12.4143C10.9889 12.0392 10.7622 11.5305 10.7622 11C10.7622 10.4696 10.9889 9.9609 11.3926 9.58583C11.7963 9.21076 12.3437 9.00004 12.9146 9.00004C13.4855 9.00004 14.033 9.21076 14.4366 9.58583C14.8403 9.9609 15.0671 10.4696 15.0671 11C15.0671 11.5305 14.8403 12.0392 14.4366 12.4143C14.033 12.7893 13.4855 13 12.9146 13Z"
                    />
                  </svg>

                  {{ userInfo.location ? userInfo.location : "No location found" }}
                </div>
                <div class="d-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
                    />
                  </svg>

                  {{ userInfo.email ? userInfo.email : "No email found" }}
                </div>
                <div class="d-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 16.42V19.956C21.0001 20.2092 20.9042 20.453 20.7316 20.6382C20.559 20.8234 20.3226 20.9363 20.07 20.954C19.633 20.984 19.276 21 19 21C10.163 21 3 13.837 3 5C3 4.724 3.015 4.367 3.046 3.93C3.06372 3.67744 3.17658 3.44101 3.3618 3.26841C3.54703 3.09581 3.79082 2.99989 4.044 3H7.58C7.70404 2.99987 7.8237 3.04586 7.91573 3.12902C8.00776 3.21218 8.0656 3.32658 8.078 3.45C8.101 3.68 8.122 3.863 8.142 4.002C8.34073 5.38892 8.748 6.73783 9.35 8.003C9.445 8.203 9.383 8.442 9.203 8.57L7.045 10.112C8.36445 13.1865 10.8145 15.6365 13.889 16.956L15.429 14.802C15.4919 14.714 15.5838 14.6509 15.6885 14.6237C15.7932 14.5964 15.9042 14.6068 16.002 14.653C17.267 15.2539 18.6156 15.6601 20.002 15.858C20.141 15.878 20.324 15.9 20.552 15.922C20.6752 15.9346 20.7894 15.9926 20.8724 16.0846C20.9553 16.1766 21.0012 16.2961 21.001 16.42H21Z"
                    />
                  </svg>

                  {{ userInfo.phone ? userInfo.phone : "No phone found" }}
                </div>
              </div>
              <div>
                <div class="lg:flex gap-x-50">
                  <div class="w-full text-left sectiony">
                    <div class="heading">Institution</div>
                    <div class="description">{{ userInfo.title ? userInfo.title : "No title found" }}</div>
                  </div>
                  <div class="w-full text-left sectiony">
                    <div class="heading">National ID</div>
                    <div class="description">{{ userInfo.NationalId ? userInfo.NationalId : "No ID found" }}</div>
                  </div>
                  <div
                    v-if="userInfo.filename"
                    class="w-full text-left sectiony"
                  >
                    <div class="heading">User CV</div>
                    <div
                      class="button flex justify-center place-items-center outlined"
                      @click="
                        download(
                          `http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/cv/${userInfo.id}`
                        )
                      "
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 2.604L21 7.604V21.612C20.9997 21.8752 20.895 22.1275 20.7088 22.3135C20.5226 22.4995 20.2702 22.604 20.007 22.604H3.993C3.73038 22.6022 3.47902 22.4971 3.29322 22.3115C3.10742 22.1259 3.00209 21.8746 3 21.612V3.596C3 3.048 3.445 2.604 3.993 2.604H16ZM12 16.604C13.0609 16.604 14.0783 16.1826 14.8284 15.4324C15.5786 14.6823 16 13.6649 16 12.604C16 11.5431 15.5786 10.5257 14.8284 9.77558C14.0783 9.02543 13.0609 8.604 12 8.604H8V16.604H12ZM10 10.604H12C12.5304 10.604 13.0391 10.8147 13.4142 11.1898C13.7893 11.5649 14 12.0736 14 12.604C14 13.1344 13.7893 13.6431 13.4142 14.0182C13.0391 14.3933 12.5304 14.604 12 14.604H10V10.604Z"
                          fill="#28A4E2"
                        />
                      </svg>

                      Download
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full text-left sectiony last">
          <div class="heading">User description</div>
          <div class="description">
            {{ userInfo.descritption ? userInfo.descritption : 'No description available' }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPane1active" class="actions flex justify-center">
      <div
        class="button important flex justify-center place-items-center"
        @click="$router.push(`/appointments/${appointmentInfo.id}/minutes`)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2V22H4V18H2V16H4V13H2V11H4V8H2V6H4V2H8ZM20.005 2C21.107 2 22 2.898 22 3.99V20.01C22 21.109 21.107 22 20.005 22H10V2H20.005Z"
            fill="white"
          />
        </svg>
        {{ "Take" }} minutes
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar-component";
import {component} from 'vue-fullscreen'
export default {
  name: "AppointmentDetails",
  props: {
    appointmentInfo: Object,
    userInfo: Object,
  },
  components: {
    Avatar,
    VueFullscreen: component
  },
  data: () => ({ 
    isPane1active: false,
    fullscreen:false 
    }),
  methods: {
    toggle () {
      this.$refs.fullscreen.toggle()
    },
    closeNotification() {
      this.showNotification = false;
      setTimeout(() => {
        this.showNotification = true;
      }, 3000);
    },
    download(url) {
      location.href = url;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "../assets/scss/imports/colors.scss";
#appointmentDetails {
  // padding: 0 48px;
  .title {
    margin-top: 26px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

    color: #b6c0c9;
    margin-bottom: 30px;
  }
  .tabs {
    display: flex;
    margin: 0 auto 30px;
    width: fit-content;
    .tab {
      padding: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: #b7bec9;
      text-align: center;
      border-bottom: 2px solid #eff2f3;
      &.active {
        color: $primary;
        font-weight: 600;
        border-bottom-color: $primary;
      }
    }
  }
  .details {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */

    color: #b6c0c9;
    .d-flex {
      margin-bottom: 30px;
      svg {
        margin-right: 15px;
        fill: #b6c0c9;
      }
      &:hover {
        color: $primary;
        svg {
          fill: $primary;
        }
      }
    }
  }
  .appointment-details {
    .d-flex {
      margin-right: 53px;
    }
    .last {
      margin-bottom: 139px;
    }
  }
  .user-details {
    .last {
      margin-bottom: 74px;
    }
    .image {
      img {
        max-width: 125px;
        width: 125px;
        height: 130px;
        border-radius: 204px;
        &.full{
          max-width: 100%;
          width: 70vh;
          height: 70vh;
          border-radius: 5%;
          margin-top: 50px;
        }
      }
      .text {
        margin-top: 15px;
        font-family: Manrope;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;

        color: #032b3d;
      }
    }
  }
  .sectiony {
    margin-bottom: 30px;
    .heading {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
      color: #bcbdbe;
      text-align: left;
    }
    .description {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;

      color: #032b3d;
      max-width: 100%;
      overflow-wrap: anywhere;
    }
  }
  .actions {
    bottom: 78px;
  }
  .button {
    width: 326px;
    height: 50px;
    border-radius: 4px;
    background: #b6c0c9;
    cursor: pointer;
    margin-right: 60px;
    &.important {
      background: $primary;
    }
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;
    &.outlined {
      border: 1px solid #28a4e2;
      background: white;
      color: $primary;
      width: 182px;
      height: 41px;
      text-decoration: underline;
    }
  }
  .margin_bottom {
    margin-bottom: 30px;
  }
}
</style>
