<template>
  <div
    id="appointment-listing"
    class="ml-8"
    v-if="$store.state.auth.user && $store.state.auth.user.roles[0].name"
  >
    <div class="flex justify-between">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          Meeting at all stages
        </h1>
        <Button
          :route="'/create-appointment/appointmentdetails'"
          title="Create Meeting"
          icon="add"
          :isSecretary="true"
        />
        <!-- v-if="$store.state.auth.user.roles[0].name === 'SECRETARY'" -->
      </div>
      <div class="mt-40 md:mt-32 block mr-6">
        <Search
          label="Search by detail,status"
          class="w-50 -ml-12"
          @input="searchAppointment"
        />
      </div>
    </div>
    <div class="flex pl-3 md:hidden" @change="generalCheck($event)">
      <h1 class="pl-2 text-xs">Select meetings</h1>
      <div
        class="mt-12 -ml-20"
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name === 'SECRETARY' ||
          $store.state.auth.user.roles[0].name === 'VIEWER'
        "
      >
        <AppointmentActionButtons
          @deleted="removedDeletedItems"
          v-if="selectedAppointments.length"
          :isSecretary="true"
          :id="selectedAppointments"
          :showIcon="true"
          :deleteFill="`${selectedAppointments.length ? '#FB2222' : '#B0B0B0'}`"
          ACTION="APPOINTMENT"
        />
      </div>
    </div>
    <div v-if="elementsAvailble">
      <table>
        <thead>
          <tr
            class="
              flex flex-col flex-no
              wrap
              table-row
              rounded-l-lg rounded-none
              mb-2 mb-0
            "
          >
            <td class="p-3 text-left xl:text-center invisible">
              <input type="checkbox" @change="generalCheck($event)" />
            </td>
            <th v-if="fields['userNames']" class="p-3 text-left">
              <span class="flex text-table text-xs"
                >Profile Name<img
                  src="icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden"
              /></span>
            </th>

            <th v-if="fields['title']" class="p-3 text-left">
              <span class="flex text-table text-xs"
                >Meeting title<img
                  src="icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden"
              /></span>
            </th>
            <th v-if="fields['status']" class="p-3 text-left">
              <span class="flex text-table text-xs xl\:-ml-24">
                Status<img
                  src="icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden"
              /></span>
            </th>

            <th v-if="fields['address']" class="p-3 text-left">
              <span class="flex text-table text-xs xl\:-ml-24">
                 Address<img
                  src="icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden"
              /></span>
            </th>

            <th v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="flex text-table text-xs xl\:-ml-40">
                Date & time<img
                  src="icons/arrow.svg"
                  alt=""
                  class="sm:w-4 sm:block hidden"
              /></span>
            </th>
            <th
              class="p-3 text-left text-table text-xs flex justify-between"
              v-if="
                $store.state.auth.user &&
                $store.state.auth.user.roles[0].name === 'SECRETARY' ||
                $store.state.auth.user.roles[0].name === 'VIEWER'
              "
            >
              <p class="xl:-ml-1">Actions</p>
              <AppointmentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedAppointments.length"
                :canBeDeleted="canBeDeleted"
                :isSecretary="true"
                :showIcon="true"
                :id="selectedAppointments"
                :deleteFill="`${
                  selectedAppointments.length ? '#FB2222' : '#B0B0B0'
                }`"
                ACTION="APPOINTMENT"
              />
            </th>
          </tr>
        </thead>
        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="(appointment, i) in appointments"
            :key="appointment.id"
            class="
              sm:flex
              sm:flex-col
              sm:flex-no
              sm:wrap
              sm:table-row
              sm:mb-2
              sm:mb-0
              h-80
              main-header
              sm:header sm:shadow-lg
            "
          >
            <td class="pt-1 p-3">
              <input
                type="checkbox"
                :checked="selectedAppointments[0] == appointment.id"
                @change="onChange(appointment.id,appointment.status !== 'COMPLETE')"
              />
            </td>
            <td v-if="fields['userNames']" class="pt-1 p-3 text-left">
              <div class="flex" v-if="appointment.appointee">
                <vue-fullscreen
                  v-if="appointment.appointee.imageUrl"
                  ref="fullscreen"
                  v-model="fullscreen"
                >
                  <img
                    v-if="appointment.appointee.imageUrl"
                    @click="toggle(i)"
                    :src="`http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/profile/${appointment.appointee.id}`"
                    alt=""
                    class="avatar"
                    :class="{ full: fullscreen }"
                  />
                </vue-fullscreen>
                <Avatar
                  v-else
                  :fullname="`${appointment.appointee.names}`"
                  :size="30"
                  color="#3399aa"
                  class="mt-1 sm:mt-2 cursor-pointer"
                >
                </Avatar>
                <p class="text-xs sm:text-values pb-1 sm:pb-0 title appointee">
                  {{
                    appointment.appointee
                      ? appointment.appointee.names
                      : "Appointee not yet attached"
                  }}
                  <!-- {{
                       cluster.institutions.length
                  ? cluster.institutions.length
                  : "Not yet attached"
                  }} -->
                </p>
              </div>
              <div class="flex" v-else>
                <p class="text-xs sm:text-values pb-1 sm:pb-0 title appointee">
                  Appointee not yet attached
                </p>
              </div>
            </td>

            <td v-if="fields['title']" class="pb-2.5 p-3">
              <div class="sm:w-44 sm:h-5 sm:p-0.5 bg-table text-center">
                <span class="sm:text-values text-xs sm:title">{{
                  appointment.title
                }}</span>
              </div>
            </td>

            <td
              v-if="fields['status']"
              class="sm:text-values sm:text-sm sm:title sm:p-3"
            >
              <div
                class="sm:w-36 sm:h-5 sm:p-0.5 sm:bg-table sm:names text-center"
              >
                <span class="text-xs sm:text-sm text-center">{{
                  appointment.status
                }}</span>
              </div>
            </td>

            <td
              v-if="fields['address']"
              class="sm:text-values sm:text-sm sm:title sm:p-3"
            >
              <div
                class="sm:w-36 sm:h-5 sm:p-0.5 sm:bg-table sm:names text-center"
              >
                <span class="text-xs sm:text-sm text-center">{{
                  appointment.address
                }}</span>
              </div>
            </td>
            <td
              v-if="fields['dateAndTime']"
              class="text-values text-xs p-3 text-left"
            >
              <p class="sm:-ml-5 date">
                {{ appointment.appointmentDateAndTime | formatDate("date") }}
                {{ appointment.appointmentDateAndTime | formatDate("time") }}
              </p>
            </td>

            <td class="text-values text-sm p-3 flex">
              <div
                class="
                  w-16
                  rounded
                  text-view
                  h-7
                  bg-table
                  p-0.5
                  pt-1
                  pb-1
                  cursor-pointer
                  text-center
                "
                @click="
                  loadAppointment(appointment);
                  showModal = true;
                "
              >
                <span class="view text-center">View</span>
              </div>
              <div
                class="
                  w-16
                  rounded
                  text-view
                  h-7
                  bg-table
                  p-0.5
                  pt-1
                  pb-1
                  -mt-7
                  sm:mt-0
                  ml-20
                  sm:ml-4
                  cursor-pointer
                  text-center
                "
                @click="editAppointment(appointment)"
              >
                <span class="view">Edit </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailble"
      :isLoading="loading"
      type="Appointments"
    />
    <Modal class="small-nav" v-show="showModal" @close="showModal = false">
      <template v-slot:content>
        <AppointmentDetails
          :appointmentInfo="appointmentInfo"
          :userInfo="userInfo"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Button from "/src/components/Listings/Button.vue";
import AppointmentActionButtons from "/src/components/Listings/AppointmentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import AppointmentDetails from "/src/components/AppointmentDetails.vue";
import Modal from "/src/components/Modal.vue";
import Api from "/src/services/Apis";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import Avatar from "vue-avatar-component";
import { component } from "vue-fullscreen";
import { mapActions } from "vuex";

export default {
  name: "AppointmentListing",
  components: {
    Button,
    AppointmentActionButtons,
    Search,
    AppointmentDetails,
    Modal,
    ElementsNotFound,
    Avatar,
    VueFullscreen: component,
  },

  data() {
    return {
      toShow: false,
      checkDisabled: false,
      fullscreen: false,
      numberOfChecks: 0,
      loading: true,
      showModal: false,
      elementsAvailble: false,
      canBeDeleted: false,
      date: [],
      appointments: [],
      selectedAppointments: [],
      appointmentInfo: {},
      userInfo: {},
      fields: [],
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    toggle(index) {
      this.$refs.fullscreen[index].toggle();
    },
    removedDeletedItems() {
      this.appointments = this.appointments.filter(
        (e) => e.id != this.selectedAppointments[0]
      );
      this.selectedAppointments = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "Appointment deleted sucessfully",
        uptime: 3000,
      });
    },
    editAppointment(appointment) {
      this.$store.commit("appointment/setSelectedAppointment", appointment);
      this.$router.push(
        `/update-appointment/${appointment.id}/appointmentdetails`
      );
    },

    generalCheck(e) {
      if (e.target.checked) {
        this.numberOfChecks++;
      } else {
        this.numberOfChecks--;
      }
      if (this.numberOfChecks > 0) {
        for (let appointment of this.appointments) {
          this.selectedAppointments.push(appointment.id);
        }
      } else {
        this.selectedAppointments = [];
      }
    },
    onChange(id, status) {
      let index = this.selectedAppointments.indexOf(id);

      if (index > -1) {
        this.selectedAppointments = [];
      } else {
        this.selectedAppointments = [id];
        this.canBeDeleted = status
      }
    },
    async getAppointments() {
      await Api.get("appointment")
        .then((response) => {
          this.appointments = response.data.content;
          if (!this.appointments.length) {
            this.elementsAvailble = false;
          } else {
            this.elementsAvailble = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async searchAppointment(searchKey) {
      if (searchKey === "") {
        this.getAppointments();
      } else {
        await Api.get("appointment/search/" + searchKey)
          .then((resp) => {
            this.appointments = resp.data;
            if (!this.appointments.length) {
              this.elementsAvailble = false;
            } else {
              this.elementsAvailble = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getFormatedReminder(reminder) {
      switch (reminder) {
        case "ONE_DAY_BEFORE":
          return "1 day before";
        case "TWO_HOURS_BEFORE":
          return "2 hours before";
        case "ONE_HOUR_BEFORE":
          return "1 hour before";
        case "THIRTY_MINUTES_BEFORE":
          return "30 minutes before";
        case "FIVE_MINUTES_BEFORE":
          return "5 minutes before";
        default:
          break;
      }
    },
    loadAppointment(appointment) {
      this.appointmentInfo = {
        id: appointment.id,
        title: appointment.title,
        location: appointment.address,
        // intentionary added a bug
        date_time: appointment.appointmentDateAndTime,
        // date_time: new Date().toISOString(),
        reminder: this.getFormatedReminder(appointment.reminder),
        description: appointment.description,
      };

      if (appointment.appointee) {
        this.userInfo = {
          id: appointment.appointee.id,
          names: appointment.appointee.names,
          location: appointment.appointee.location,
          phone: appointment.appointee.mobile,
          email: appointment.appointee.email,
          NationalId: appointment.appointee.nationalId,
          hasProfile: appointment.appointee.imageUrl != null,
          descritption: appointment.appointee.description,
          filename: appointment.appointee.cvPath,
          title: appointment.appointee.title,
        };
      } else {
        this.userInfo = {};
      }
    },
  },

  async created() {
    this.fields = await this.getFields("appointments_table");
    this.getAppointments();
  },
};
</script>

<style scoped>
.avatar {
  margin: 0 0 -1.4rem 0;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.avatar.full {
  max-width: 100%;
  width: 70vh;
  height: 70vh;
  border-radius: 5%;
  margin: 50px auto 0px;
}
table {
  width: 97%;
  border-collapse: collapse;
}
.appointments {
  font-weight: 600 !important;
}
.search-area {
  background: url("/icons/search.svg");
  background-position: 10rem 8px;
  background-repeat: no-repeat;
  background-color: white;
}
.appointee {
  margin: 0.5rem 0 0 0.5rem !important;
}
.date {
  font-size: 10px !important;
}
table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.title {
  font-weight: 600 !important;
}
.header {
  background: white !important;
  height: 3.5rem !important;
}
.names {
  margin: 0 3rem 0 -8rem !important;
}
@media only screen and (max-width: 760px),
  (min-device-width: 250px) and (max-device-width: 640px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    background: white;
    width: 20rem;
    margin: 1rem 0 0 0;
    box-shadow: 0 10px 15px -3px rgb(0, 0, 0 / 10%);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 12px;
    text-align: justify;
    color: #000000;
  }

  td:nth-of-type(2):before {
    content: "Profile Name";
  }
  td:nth-of-type(3):before {
    content: "Meeting details";
  }
  td:nth-of-type(4):before {
    content: "Status";
  }
  td:nth-of-type(5):before {
    content: "Date & Time ";
  }
  td:nth-of-type(6):before {
    content: "Actions";
  }
}
</style>
